<template>
    <div>
        <van-cell-group v-for="item in list" :key="item + 'DepI'">
            <van-image style="margin:5%; float:left;" width="4em" height="4em" fit="contain" :src="logos"/>
            <van-cell style="margin-top:5%; float:left; width:70%;">
                <template slot="title">
                    <span style="font-size:18px;">{{item.depTitle}}</span>
                </template>
                <template slot="label">
                    <van-tag color="#57c4b7">详情</van-tag>
                </template>
            </van-cell>
            <!-- <van-image style="margin:5%;" width="90%" height="90%" fit="contain" :src="imgsrc"/> -->
            <van-cell style="text-indent:2em;" v-for="Ditem in val" :key="Ditem + 'DepI'">{{Ditem}}</van-cell>
        </van-cell-group>
    </div>
</template>
<script>
import store from '../../store'
import ajax from '../../lib/ajax';
import { Toast } from 'vant';
export default {
    data() {
        return {
            list:[],
            val:[],
            logos: require('@/assets/FGlogo.png'),
            imgsrc: require('@/assets/FGInfo.png'),
            // logos: require('@/assets/JClogo.png'),
            // imgsrc: require('@/assets/JCInfo.png'),
            // logos: require('@/assets/LClogo.jpg'),
            // imgsrc: require('@/assets/LCInfo.jpeg'),
        }
    },
    created(){
        this.onload();
    },
    methods:{
        async onload(){
            let depname = store.getters["store/depname"];
            // alert(depname);
            await ajax.get("/Api/QueryService/OutputDepartmentsInfo?input=" + depname)
            .then((rep) => {
                this.list = rep.data.result;
                console.log(this.list);
                for (let i = 0; i < this.list.length; i++) {
                    this.val = this.list[i].depContent.split("&");
                    console.log(this.val);
                }
                console.log(this.list);
            }).catch((err) => {
                Toast("错误：" + err.message);
            })
        }
    }
}
</script>
<style>

</style>